var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _c(
      "div",
      { staticClass: "formGroup" },
      [
        _c("app-textbox", {
          attrs: {
            id: "officeIdOld",
            type: "hidden",
            value: _vm.$options.officeId
          }
        }),
        _c("app-dropdownlist", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.hasMultipleOffices,
              expression: "hasMultipleOffices"
            }
          ],
          attrs: {
            id: "officeId",
            placeholder: _vm.$t("offices.office"),
            dataSource: _vm.offices,
            fields: { text: "name", value: "id" }
          },
          model: {
            value: _vm.data.officeId,
            callback: function($$v) {
              _vm.$set(_vm.data, "officeId", $$v)
            },
            expression: "data.officeId"
          }
        }),
        _c("app-textbox", {
          attrs: {
            id: "serviceIdOld",
            type: "hidden",
            value: _vm.$options.serviceId
          }
        }),
        _c("app-dropdownlist", {
          attrs: {
            id: "serviceId",
            placeholder: _vm.$t("services.service"),
            dataSource: _vm.officeServices,
            fields: { text: "name", value: "id" },
            enabled: _vm.isOfficeSelected
          },
          model: {
            value: _vm.data.serviceId,
            callback: function($$v) {
              _vm.$set(_vm.data, "serviceId", $$v)
            },
            expression: "data.serviceId"
          }
        })
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "formGroup" },
      [
        _c("app-numerictextbox", {
          attrs: {
            id: "price",
            placeholder: _vm.$t("shared.price"),
            format: "C2",
            enabled: _vm.isOfficeSelected
          },
          model: {
            value: _vm.data.price,
            callback: function($$v) {
              _vm.$set(_vm.data, "price", $$v)
            },
            expression: "data.price"
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }